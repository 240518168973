import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { collection, getDocs } from "firebase/firestore";
import { db } from "src/config/configFirebase";
import "./styles.css";

export default function App() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchSlides = async () => {
      const slidesCollection = collection(db, "slidesLanding");
      const slidesSnapshot = await getDocs(slidesCollection);
      const slidesList = slidesSnapshot.docs.map((doc) => doc.data());
      setSlides(slidesList);
    };

    fetchSlides();
  }, []);

  return (
    <>
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={isDesktop}
        modules={[Autoplay, Pagination, Navigation]}
        className="mt-[77px]"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <a href={slide.link} target="_blank" className="w-full">
              <img
                src={isDesktop ? slide.imageDesktop : slide.imageMobile}
                alt={`Slide ${index + 1}`}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
